import React, { lazy } from "react";
import {
  DRIVER_ADD,
  DRIVER,
  DRIVER_EDIT,
  HOME,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  MAP,
} from "@jumbo/constants/routes";
import PrivateRoute from "./PrivateRoute";
import EditProfile from "app/pages/auth/EditProfile";
import Error401 from "app/pages/401";

const Page = lazy(() => import("@jumbo/shared/Page"));
const User = lazy(() => import("../pages/user/view"));
const UpdateAddUser = lazy(() => import("../pages/user/addAndUpdate"));
const ChangePassword = lazy(() => import("../pages/auth/ChangePassword"));
const Map = lazy(() => import("../pages/map"));

const dashboardRoutes = [
  {
    path: "401",
    element: (
      <PrivateRoute>
        <Page component={Error401} />
      </PrivateRoute>
    ),
  },
  {
    path: HOME,
    element: (
      <PrivateRoute>
        <Page component={User} />
        </PrivateRoute>
    ),
  },
  {
    path: DRIVER,
    element: (
      <PrivateRoute>
        <Page component={User} />
      </PrivateRoute>
    ),
  },
  {
    path: DRIVER_ADD,
    element: (
      <PrivateRoute>
        <Page component={UpdateAddUser} />
      </PrivateRoute>
    ),
  },
  {
    path: DRIVER_EDIT,
    element: (
      <PrivateRoute>
        <Page component={UpdateAddUser} />
      </PrivateRoute>
    ),
  },
  {
    path: CHANGE_PASSWORD,
    element: (
      <PrivateRoute>
        <Page component={ChangePassword} />
      </PrivateRoute>
    ),
  },
  {
    path: EDIT_PROFILE,
    element: (
      <PrivateRoute>
        <Page component={EditProfile} />
      </PrivateRoute>
    ),
  },
  {
    path: MAP,
    element: (
      <PrivateRoute>
        <Page component={Map} />
      </PrivateRoute>
    ),
  },
];

export default dashboardRoutes;
