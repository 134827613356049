import axios from 'axios';

export const url = process.env.REACT_APP_API_BASE_URL;

const axiosUtil = {
  initalise: (store) => {
    axios.defaults.baseURL = url;
    axios.defaults.withCredentials = true;

    axios.interceptors.request.use(
      (axiosConfig) => {
        axiosConfig.headers['strict-transport-security'] = 'max-age=63072000; includeSubdomains; preload';
        axiosConfig.headers['content-security-policy'] =
          "default-src 'none'; img-src 'self'; script-src 'self'; style-src 'self'; object-src 'none'";
        axiosConfig.headers['x-content-type-options'] = 'nosniff';
        axiosConfig.headers['x-frame-options'] = 'DENY';
        axiosConfig.headers['x-xss-protection'] = '1; mode=block';
        axiosConfig.headers['Content-Type'] = 'application/json';
        return axiosConfig;
      },
      (error) => {
        Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        return Promise.reject(error.response);
      },
    );
  },
};

export default axiosUtil;
