import { useRoutes } from "react-router-dom";
import { buildRoutes } from "@jumbo/utils";

let generatedRoutes = [];

const useJumboRoutes = (routes) => {
  generatedRoutes = buildRoutes([...routes]);

  return useRoutes(generatedRoutes);
};

export default useJumboRoutes;
