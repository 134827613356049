import React from "react";
import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";

const JumboLoadingButton = ({
  color,
  onClick,
  loading,
  loadingPosition,
  startIcon,
  variant,
  type,
  buttonLabel,
  ...rest
}) => {
  return (
    <LoadingButton
      {...rest}
      color={color}
      onClick={onClick}
      loading={loading}
      loadingPosition={loadingPosition}
      startIcon={startIcon}
      variant={variant}
      type={type}
    >
      {buttonLabel}
    </LoadingButton>
  );
};

JumboLoadingButton.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  loadingPosition: PropTypes.string,
  startIcon: PropTypes.any,
  variant: PropTypes.string,
  type: PropTypes.string,
};

JumboLoadingButton.defaultProps = {
  variant: "contained",
  type: "submit",
  loadingPosition: "start",
};

export default JumboLoadingButton;
