import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import JumboBreadCrumb from '../JumboBreadCrumb';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
});

const PageContainer = ({ heading, breadcrumb, children }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.header}>
        <Typography variant={'h1'} mb={3}>
          {heading}
        </Typography>
        <JumboBreadCrumb breadcrumb={breadcrumb} />
      </Box>
      {children}
    </>
  );
};

PageContainer.defaultProps = {
  heading: ' ',
  breadcrumb: [],
  children:null,
};

PageContainer.propTypes = {
  heading: PropTypes.string,
  breadcrumb: PropTypes.array,
  children: PropTypes.node,
};

export default PageContainer;
