import React from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Close, Dashboard, Person, Save } from "@mui/icons-material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Div from "@jumbo/shared/Div";
import { HOME } from "@jumbo/constants/routes";
import JumboAppTextField from "@jumbo/components/JumboAppTextField";
import PageContainer from "@jumbo/components/PageContainer";
import LoadingButton from "@jumbo/components/JumboLoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { editProfile } from "app/redux/slice/authSlice";

const validationSchema = Yup.object({
  name: Yup.string().required("The Name field is required."),
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("The Email field is required"),
  mobileNumber: Yup.string()
    .required("Phone number is required.")
    .matches(/^[123456789]\d{9}$/, "Phone number is not valid"),
});

const EditProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const mutation = { isError: false };
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const authUser = useSelector((state) => state?.auth?.loggedUser);

  const breadcrumb = [
    {
      path: HOME,
      icon: <Dashboard />,
      label: "Dashboard",
    },
    {
      icon: <Person />,
      label: "Edit Profile",
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: authUser?.name,
      email: authUser?.email,
      mobileNumber: authUser?.mobileNumber,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newUserData = {
        name: values.name?.trim(),
        email: values.email?.trim(),
        mobileNumber: values.mobileNumber?.trim(),
        roleId: authUser?.roleId?._id,
      };
      const response = await dispatch(
        editProfile({ id: authUser?._id, data: newUserData })
      ).unwrap();
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: "success",
        });
        navigate(HOME);
        resetForm();
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: "error",
        });
      }
      setSubmitting(false);
    },
  });
  const { handleSubmit, isSubmitting } = formik;
  return (
    <PageContainer heading={"Edit Profile"} breadcrumb={breadcrumb}>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          padding: 3,
          borderColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <FormikProvider value={formik}>
          <Form
            style={{ textAlign: "left" }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            {mutation.isError && <p>{mutation.error.message}</p>}
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <JumboAppTextField fullWidth name="name" label="Name*" />
              </Grid>
              <Grid item xs={12} md={12}>
                <JumboAppTextField fullWidth name="email" label="Email*" />
              </Grid>
              <Grid item xs={12} md={12}>
                <JumboAppTextField
                  fullWidth
                  name="mobileNumber"
                  label="Mobile No*"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Div sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                <LoadingButton
                  buttonLabel={id ? "Update" : "Save"}
                  startIcon={<Save />}
                  variant="outlined"
                  sx={{ mb: 3, width: "fit-content", marginRight: 2.5 }}
                  loading={isSubmitting || mutation.isLoading}
                  type="submit"
                />
                <LoadingButton
                  color={"secondary"}
                  buttonLabel={"Close"}
                  variant="outlined"
                  startIcon={<Close />}
                  sx={{ mb: 3, width: "fit-content" }}
                  onClick={() => {
                    navigate(HOME);
                  }}
                />
              </Div>
            </Grid>
          </Form>
        </FormikProvider>
      </Box>
    </PageContainer>
  );
};

export default EditProfile;
