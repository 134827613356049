import React from "react";
import ToysIcon from '@mui/icons-material/Toys';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { DRIVER,MAP } from "@jumbo/constants/routes";

const menus = [
  {
    label: "Home",
    type: "section",
    children: [
      {
        uri: DRIVER,
        label: "Drivers",
        type: "nav-item",
        icon: <ToysIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: MAP,
        label: "Map",
        type: "nav-item",
        icon: <LocationOnIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
