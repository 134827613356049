import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

function PrivateRoute({ children }) {
  const result = JSON.parse(localStorage.getItem("User"));

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (!result) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      return; // No need to continue rendering
    }
    setLoader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathname,
    requestedLocation,
    result,
  ]);

  if (!result) {
    return <Navigate to="/login" />;
  }
  if (!loader) {
    return <Navigate to="/401" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
