import React from "react";
import { Link } from "react-router-dom";

import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../utils/constants/paths";
import { HOME } from "@jumbo/constants/routes";

const Logo = ({ mode, sx }) => {
  return (
    <Div sx={{ display: "inline-flex", ...sx }}>
      <Link to={HOME}>
        <img
          src={
            mode === "light"
              ? `${ASSET_IMAGES}/logo.png`
              : `${ASSET_IMAGES}/logo-white.png`
          }
          alt="Jumbo React"
        />
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: "light",
};

export default Logo;
