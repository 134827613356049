import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Zoom,
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { Close, Save } from "@mui/icons-material";

import Button from "@jumbo/components/JumboLoadingButton";

const DialogBase = ({
  content,
  open,
  actions,
  title,
  subheader,
  contentProps,
  onSubmit,
  onClose,
  TransitionComponent,
  disableDefaultClose,
  buttonLabel,
  icon,
  isLoading,
  variant,
  ...restProps
}) => {
  return (
    <Dialog
      open={open}
      {...restProps}
      onClose={onClose}
      TransitionComponent={TransitionComponent}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px",
        }}
      >
        {title && (
          <CardHeader
            title={title}
            subheader={subheader}
            sx={{ pb: 0, pl: 2 }}
          />
        )}
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <DialogContent {...contentProps}>{content}</DialogContent>

      <DialogActions>
        {actions ? (
          actions
        ) : (
          <>
            <Button
              buttonLabel={buttonLabel.confirm}
              startIcon={icon.confirm}
              variant={variant}
              onClick={onSubmit}
              loading={isLoading}
            />
            <Button
              color={"secondary"}
              buttonLabel={buttonLabel.cancel}
              variant={variant}
              startIcon={icon.close}
              onClick={onClose}
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

DialogBase.propTypes = {
  content: PropTypes.node,
  actions: PropTypes.node,
  open: PropTypes.bool,
  title: PropTypes.string,
  subheader: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  disableDefaultClose: PropTypes.bool,
  buttonLabel: PropTypes.object,
  icon: PropTypes.object,
  isLoading: PropTypes.bool,
};

DialogBase.defaultProps = {
  onClose: () => {},
  TransitionComponent: Zoom,
  disableDefaultClose: false,
  buttonLabel: {
    cancel: "Cancel",
    confirm: "Confirm",
  },
  icon: {
    confirm: <Save />,
    close: <Close />,
  },
  variant: "outlined",
  isLoading:false,
};

export default DialogBase;
