import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "end",
    marginBottom:20
  },
  linkStyle: {
    display: "flex",
    alignItems: "center",
    color: "inherit",
    textDecoration: "none",
  },
  iconStyle: {
    marginBottom: "-4px",
    "& .MuiSvgIcon-root ": {
      fontSize: "inherit",
      marginRight: "4px",
    },
  },
  active: {
    display: "flex",
    alignItems: "center",
  },
}));

const JumboBreadCrumb = ({ breadcrumb }) => {
  const classes = useStyles();
  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes?.root} >
      {breadcrumb?.length &&
        breadcrumb.map((item, index) =>
          item?.path ? (
            <Link key={index} className={classes?.linkStyle} to={item?.path}>
              <span className={classes?.iconStyle}>{item?.icon}</span>
              {item?.label}
            </Link>
          ) : (
            <Typography className={classes?.active} color="text.primary">
              <span className={classes?.iconStyle}>{item?.icon}</span>
              {item?.label}
            </Typography>
          )
        )}
    </Breadcrumbs>
  );
};

JumboBreadCrumb.propTypes = {
  breadcrumb: PropTypes.array,
};

JumboBreadCrumb.defaultProps = {
  breadcrumb: [],
};

export default JumboBreadCrumb;
