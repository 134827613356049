export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/set-password';
export const HOME = '/';
export const CHANGE_PASSWORD = '/change-password';
export const EDIT_PROFILE = '/edit-profile'

export const MAP = "/drivers/map";

export const DRIVER = "/drivers";
export const DRIVER_ADD = "/add-drivers";
export const DRIVER_EDIT = "/update-drivers/:id";
export const createDriverEditRoute = (id) => `/update-drivers/${id}`;