import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  perPage: "",
  total: 0,
  loading: false,
};

export const getDriverList = createAsyncThunk(
  "get-driver",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("driver/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createDriver = createAsyncThunk(
  "create-driver",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("driver", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editDriver = createAsyncThunk(
  "edit-driver",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`driver/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updateDriver = createAsyncThunk(
  "update-driver",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/driver/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);
export const updateDriverStatus = createAsyncThunk(
  "update-driver-status",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/driver/status/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);
export const deleteDriver = createAsyncThunk(
  "delete-driver",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/driver/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDriverList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDriverList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.driverList;
        state.perPage = action?.payload?.data?.data?.perPage;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getDriverList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
  },
});

export default driverSlice.reducer;
