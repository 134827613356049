import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Div from '@jumbo/shared/Div';
import { getAssetPath } from 'app/utils/appHelpers';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { userType } from 'app/helpers/userType';
import { EDIT_PROFILE, HOME } from '@jumbo/constants/routes';

const Error401
  = () => {
    const navigate = useNavigate();
    const { isMA } = userType();

    const [permissionData] = useState(JSON.parse(localStorage.getItem('permission')));

    const [permission, setPermission] = useState({
      dashboardPermission: false
    });
    const getPermission = () => {
      if (isMA) {
        setPermission({ dashboardPermission: true });
      } else {
        let permissionValue = {
          dashboardPermission: false
        };
        const dashboardPermission = permissionData?.filter((item) => {
          return item?.moduleName?.includes('Dashboard') && item;
        });
        dashboardPermission?.length &&
          dashboardPermission.forEach(function (num) {
            if (num?.name === 'dashboard') {
              permissionValue.dashboardPermission = true;
            }
          });
        setPermission(permissionValue);
      }
    };
    useEffect(() => {
      getPermission();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <Div
        sx={{
          flex: 1,
          flexWrap: 'wrap',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: (theme) => theme.spacing(4),
        }}
      >
        <Div sx={{ display: 'inline-flex', mb: 3 }}>
          <img
            src={getAssetPath(`${ASSET_IMAGES}/401.svg`, '380x206')}
            alt="401"
            width={380}
          />
        </Div>
        <Button variant="contained" onClick={() => navigate(permission.dashboardPermission ? HOME : EDIT_PROFILE)}>
          Go to home
        </Button>
      </Div>
    );
  };

export default Error401;
