import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Check, Close } from "@mui/icons-material";

import Div from "@jumbo/shared/Div";
import Button from "@jumbo/components/JumboLoadingButton";

const useStyles = makeStyles({
  dialogTitle: {
    paddingTop: "22px",
    paddingBottom: "2px",
    fontSize: 20,
  },
});

const DialogConfirm = ({
  title,
  message,
  open,
  onConfirm,
  onClose,
  maxWidth,
  isLoading,
  buttonLabel,
  icon,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      TransitionComponent={Zoom}
      keepMounted
      onClose={onClose}
      maxWidth={maxWidth}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogTitle className={classes?.dialogTitle}>{title}</DialogTitle>
        <Close onClick={onClose} sx={{ marginRight: 2.5 }} />
      </Div>

      <DialogContent sx={{ pb: 2, my: 0, fontSize: 15 }}>
        {message}
      </DialogContent>
      <DialogActions>
        <Button
          buttonLabel={buttonLabel.confirm}
          startIcon={icon?.confirm}
          variant="outlined"
          onClick={onConfirm}
          loading={isLoading}
        />
        <Button
          color={"secondary"}
          buttonLabel={buttonLabel.cancel}
          variant="outlined"
          startIcon={icon?.cancel}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

DialogConfirm.propTypes = {
  title: PropTypes.node,
  message: PropTypes.node,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoading: PropTypes.bool,
  buttonLabel: PropTypes.object,
  icon:PropTypes.object,
};
DialogConfirm.defaultProps = {
  maxWidth: "sm",
  buttonLabel: {
    cancel: "Cancel",
    confirm: "Confirm",
  },
  icon: {
    cancel: <Close />,
    confirm: <Check />,
  },
  isLoading: false,
};

export default DialogConfirm;
