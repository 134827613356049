import React from "react";
import Avatar from "@mui/material/Avatar";
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { CHANGE_PASSWORD, EDIT_PROFILE, LOGIN } from "@jumbo/constants/routes";
import { logoutUser } from "app/redux/slice/authSlice";

const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state?.auth?.loggedUser);

  const onLogout = () => {
    dispatch(logoutUser(authUser?._id))
      .unwrap()
      .then((res) => {
        if (res?.status === 200) {
          localStorage.clear();
          navigate(LOGIN);
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={authUser?.profile_pic}
            sizes={"small"}
            sx={{ boxShadow: 25, cursor: "pointer" }}
          />
        }
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            p: (theme) => theme.spacing(2.5),
          }}
        >
          <Avatar
            src={authUser?.profile_pic}
            alt={authUser?.name}
            sx={{ width: 60, height: 60, mb: 2 }}
          />
          <Typography variant={"h5"}>{authUser?.name}</Typography>
          <Typography variant={"body1"} color="text.secondary">
            {authUser?.email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            {/* <ListItemButton onClick={() => navigate(EDIT_PROFILE)}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" sx={{ my: 0 }} />
            </ListItemButton> */}
            <ListItemButton onClick={() => navigate(CHANGE_PASSWORD)}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <VpnKeyOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Change Password" sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
